.App {
    text-align: center;
}

.stage {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.main-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 25%;
    margin-right: 45%;
}

.list {
    margin-left: 100px;
    height: 500px;
}

.delete-button {
    margin-top: 20px;
    width: 100px;
}